import { GraphQLClient } from 'graphql-request';
import { mergeAll } from 'ramda'

if (true) {
  require('cross-fetch/polyfill');
}

export default (ctx, inject) => {
  const baseOptions = {"includeNodeModules":false,"useFetchPolyfill":true,"options":{},"clients":{"shopify":{"endpoint":"https:\u002F\u002Funited-fashion-service.myshopify.com\u002Fapi\u002F2023-01\u002Fgraphql.json","options":{"headers":{"X-Shopify-Storefront-Access-Token":"4eb90ceb5f788f71ca6a4b0a9dbef37e","content-type":"application\u002Fgraphql"}}}}};

  const graphClients = Object.entries(baseOptions.clients)
    .reduce((clients, [clientName, config]) => {
      const runtimeConfig = (
        ctx.$config
        && ctx.$config.graphql
        && ctx.$config.graphql.clients
        && ctx.$config.graphql.clients[clientName]
      ) || {};

      const clientConfig = mergeAll([baseOptions, config, runtimeConfig])

      return {
        ...clients,
        [clientName]: new GraphQLClient(clientConfig.endpoint, clientConfig.options),
      };
    }, {});

  ctx.$graphql = graphClients;
  inject('graphql', graphClients);
};
