
import FullscreenMenu from '~/components/navigation/menu/FullscreenMenu.vue';
import MenuBar from '~/components/navigation/menu/MenuBar.vue';
import CookieBanner from '~/components/cookie/CookieBanner.vue';
import BigFooter from '~/components/navigation/BigFooter.vue';

export default {
  components: {
    FullscreenMenu,
    MenuBar,
    CookieBanner,
    BigFooter
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true, addDirAttribute: true });
    return {
      bodyAttrs: {
        class: 'dark-theme'
      },
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    };
  }
};
