import { render, staticRenderFns } from "./LogoBlack.vue?vue&type=template&id=985c3692&scoped=true&"
import script from "./LogoBlack.vue?vue&type=script&lang=ts&"
export * from "./LogoBlack.vue?vue&type=script&lang=ts&"
import style0 from "./LogoBlack.vue?vue&type=style&index=0&id=985c3692&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "985c3692",
  null
  
)

export default component.exports