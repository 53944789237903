
import IconPlus from '~/components/icons/IconPlus.vue';

export default {
  components: { IconPlus },
  props: {
    buttonTextAccept: {
      type: String,
      default: 'Accept'
    },
    buttonTextDeny: {
      type: String,
      default: 'Deny'
    },
    message: {
      type: String,
      default:
        'We use cookies to provide our services and for analytics and marketing. To find out more about our use of cookies, please see our Privacy Policy. By continuing to browse our website, you agree to our use of cookies.'
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  created () {
    if (!this.getCookieState() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getCookieState () {
      if (process.browser) {
        return localStorage.getItem('CookieBanner', true);
      }
    },
    hide () {
      if (process.browser) {
        this.isOpen = false;
        localStorage.setItem('CookieBanner', true);
      }
    }
  }
};
