
export default {
  props: {
    border: {
      type: Boolean,
      default: true
    },
    borderWhite: {
      type: Boolean,
      default: false
    }
  }
};
