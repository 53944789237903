
import IconArrowRight from '~/components/icons/IconArrowRight.vue';

export default {
  components: { IconArrowRight },
  layout: 'basic',
  /* eslint-disable-next-line */
  props: ['error'],
  title: 'Not found',
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true, addDirAttribute: true });
    return {
      bodyAttrs: {
        class: ''
      },
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    };
  },
  mounted () {
    console.error(this.error);
  }
};
