import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _075b1a0e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _763bbb74 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _e99bad26 = () => interopDefault(import('../pages/collection.vue' /* webpackChunkName: "pages/collection" */))
const _113aaaed = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _693bfc36 = () => interopDefault(import('../pages/components.vue' /* webpackChunkName: "pages/components" */))
const _c10cba3e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2759cad2 = () => interopDefault(import('../pages/dataprotection.vue' /* webpackChunkName: "pages/dataprotection" */))
const _2712f4d3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _45d10654 = () => interopDefault(import('../pages/dev.vue' /* webpackChunkName: "pages/dev" */))
const _47009e4a = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _2a18f2f6 = () => interopDefault(import('../pages/service.vue' /* webpackChunkName: "pages/service" */))
const _10176470 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _56081404 = () => interopDefault(import('../pages/withdrawal.vue' /* webpackChunkName: "pages/withdrawal" */))
const _b9510368 = () => interopDefault(import('../pages/blog/comecloser.vue' /* webpackChunkName: "pages/blog/comecloser" */))
const _0378329b = () => interopDefault(import('../pages/blog/design-furniture-meets-fashion.vue' /* webpackChunkName: "pages/blog/design-furniture-meets-fashion" */))
const _55ed8da6 = () => interopDefault(import('../pages/blog/EraOfChangeInFashion.vue' /* webpackChunkName: "pages/blog/EraOfChangeInFashion" */))
const _681e6564 = () => interopDefault(import('../pages/blog/generationZ.vue' /* webpackChunkName: "pages/blog/generationZ" */))
const _c8c55e58 = () => interopDefault(import('../pages/blog/hamcollection.vue' /* webpackChunkName: "pages/blog/hamcollection" */))
const _0bbb73ea = () => interopDefault(import('../pages/blog/Nachhaltigkeit.vue' /* webpackChunkName: "pages/blog/Nachhaltigkeit" */))
const _034c7b12 = () => interopDefault(import('../pages/blog/UFSGlobalCommunity.vue' /* webpackChunkName: "pages/blog/UFSGlobalCommunity" */))
const _64affeec = () => interopDefault(import('../pages/community/andreberger.vue' /* webpackChunkName: "pages/community/andreberger" */))
const _541140fc = () => interopDefault(import('../pages/community/andremoellersmann.vue' /* webpackChunkName: "pages/community/andremoellersmann" */))
const _27701386 = () => interopDefault(import('../pages/community/gesineglueck.vue' /* webpackChunkName: "pages/community/gesineglueck" */))
const _6ab365d2 = () => interopDefault(import('../pages/community/kathrindanner.vue' /* webpackChunkName: "pages/community/kathrindanner" */))
const _0c024a3e = () => interopDefault(import('../pages/community/klauspomella.vue' /* webpackChunkName: "pages/community/klauspomella" */))
const _41e9bfbc = () => interopDefault(import('../pages/community/louisberger.vue' /* webpackChunkName: "pages/community/louisberger" */))
const _41c089d9 = () => interopDefault(import('../pages/community/sarakrupper.vue' /* webpackChunkName: "pages/community/sarakrupper" */))
const _0b3b53c3 = () => interopDefault(import('../pages/community/vanessahot.vue' /* webpackChunkName: "pages/community/vanessahot" */))
const _85fd9370 = () => interopDefault(import('../pages/shop/preorder/index.vue' /* webpackChunkName: "pages/shop/preorder/index" */))
const _e3428db0 = () => interopDefault(import('../pages/shop/shipping.vue' /* webpackChunkName: "pages/shop/shipping" */))
const _131c3780 = () => interopDefault(import('../pages/shop/preorder/checkout.vue' /* webpackChunkName: "pages/shop/preorder/checkout" */))
const _6ff4bd28 = () => interopDefault(import('../pages/shop/preorder/status.vue' /* webpackChunkName: "pages/shop/preorder/status" */))
const _5a8a6304 = () => interopDefault(import('../pages/shop/collections/_collectionHandle/index.vue' /* webpackChunkName: "pages/shop/collections/_collectionHandle/index" */))
const _e912620e = () => interopDefault(import('../pages/shop/products/_productHandle.vue' /* webpackChunkName: "pages/shop/products/_productHandle" */))
const _3afee596 = () => interopDefault(import('../pages/shop/collections/_collectionHandle/products/_productHandle.vue' /* webpackChunkName: "pages/shop/collections/_collectionHandle/products/_productHandle" */))
const _1977ba0c = () => interopDefault(import('../pages/blog/_name.vue' /* webpackChunkName: "pages/blog/_name" */))
const _1b52b190 = () => interopDefault(import('../pages/changelog/_slug.vue' /* webpackChunkName: "pages/changelog/_slug" */))
const _9712acf6 = () => interopDefault(import('../pages/community/_name.vue' /* webpackChunkName: "pages/community/_name" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _075b1a0e,
    name: "about___en"
  }, {
    path: "/blog",
    component: _763bbb74,
    name: "blog___en"
  }, {
    path: "/collection",
    component: _e99bad26,
    name: "collection___en"
  }, {
    path: "/community",
    component: _113aaaed,
    name: "community___en"
  }, {
    path: "/components",
    component: _693bfc36,
    name: "components___en"
  }, {
    path: "/contact",
    component: _c10cba3e,
    name: "contact___en"
  }, {
    path: "/dataprotection",
    component: _2759cad2,
    name: "dataprotection___en"
  }, {
    path: "/de",
    component: _2712f4d3,
    name: "index___de"
  }, {
    path: "/dev",
    component: _45d10654,
    name: "dev___en"
  }, {
    path: "/imprint",
    component: _47009e4a,
    name: "imprint___en"
  }, {
    path: "/service",
    component: _2a18f2f6,
    name: "service___en"
  }, {
    path: "/shop",
    component: _10176470,
    name: "shop___en"
  }, {
    path: "/withdrawal",
    component: _56081404,
    name: "withdrawal___en"
  }, {
    path: "/blog/comecloser",
    component: _b9510368,
    name: "blog-comecloser___en"
  }, {
    path: "/blog/design-furniture-meets-fashion",
    component: _0378329b,
    name: "blog-design-furniture-meets-fashion___en"
  }, {
    path: "/blog/EraOfChangeInFashion",
    component: _55ed8da6,
    name: "blog-EraOfChangeInFashion___en"
  }, {
    path: "/blog/generationZ",
    component: _681e6564,
    name: "blog-generationZ___en"
  }, {
    path: "/blog/hamcollection",
    component: _c8c55e58,
    name: "blog-hamcollection___en"
  }, {
    path: "/blog/Nachhaltigkeit",
    component: _0bbb73ea,
    name: "blog-Nachhaltigkeit___en"
  }, {
    path: "/blog/UFSGlobalCommunity",
    component: _034c7b12,
    name: "blog-UFSGlobalCommunity___en"
  }, {
    path: "/community/andreberger",
    component: _64affeec,
    name: "community-andreberger___en"
  }, {
    path: "/community/andremoellersmann",
    component: _541140fc,
    name: "community-andremoellersmann___en"
  }, {
    path: "/community/gesineglueck",
    component: _27701386,
    name: "community-gesineglueck___en"
  }, {
    path: "/community/kathrindanner",
    component: _6ab365d2,
    name: "community-kathrindanner___en"
  }, {
    path: "/community/klauspomella",
    component: _0c024a3e,
    name: "community-klauspomella___en"
  }, {
    path: "/community/louisberger",
    component: _41e9bfbc,
    name: "community-louisberger___en"
  }, {
    path: "/community/sarakrupper",
    component: _41c089d9,
    name: "community-sarakrupper___en"
  }, {
    path: "/community/vanessahot",
    component: _0b3b53c3,
    name: "community-vanessahot___en"
  }, {
    path: "/de/about",
    component: _075b1a0e,
    name: "about___de"
  }, {
    path: "/de/blog",
    component: _763bbb74,
    name: "blog___de"
  }, {
    path: "/de/collection",
    component: _e99bad26,
    name: "collection___de"
  }, {
    path: "/de/community",
    component: _113aaaed,
    name: "community___de"
  }, {
    path: "/de/components",
    component: _693bfc36,
    name: "components___de"
  }, {
    path: "/de/contact",
    component: _c10cba3e,
    name: "contact___de"
  }, {
    path: "/de/dataprotection",
    component: _2759cad2,
    name: "dataprotection___de"
  }, {
    path: "/de/dev",
    component: _45d10654,
    name: "dev___de"
  }, {
    path: "/de/imprint",
    component: _47009e4a,
    name: "imprint___de"
  }, {
    path: "/de/service",
    component: _2a18f2f6,
    name: "service___de"
  }, {
    path: "/de/shop",
    component: _10176470,
    name: "shop___de"
  }, {
    path: "/de/withdrawal",
    component: _56081404,
    name: "withdrawal___de"
  }, {
    path: "/shop/preorder",
    component: _85fd9370,
    name: "shop-preorder___en"
  }, {
    path: "/shop/shipping",
    component: _e3428db0,
    name: "shop-shipping___en"
  }, {
    path: "/de/blog/comecloser",
    component: _b9510368,
    name: "blog-comecloser___de"
  }, {
    path: "/de/blog/design-furniture-meets-fashion",
    component: _0378329b,
    name: "blog-design-furniture-meets-fashion___de"
  }, {
    path: "/de/blog/EraOfChangeInFashion",
    component: _55ed8da6,
    name: "blog-EraOfChangeInFashion___de"
  }, {
    path: "/de/blog/generationZ",
    component: _681e6564,
    name: "blog-generationZ___de"
  }, {
    path: "/de/blog/hamcollection",
    component: _c8c55e58,
    name: "blog-hamcollection___de"
  }, {
    path: "/de/blog/Nachhaltigkeit",
    component: _0bbb73ea,
    name: "blog-Nachhaltigkeit___de"
  }, {
    path: "/de/blog/UFSGlobalCommunity",
    component: _034c7b12,
    name: "blog-UFSGlobalCommunity___de"
  }, {
    path: "/de/community/andreberger",
    component: _64affeec,
    name: "community-andreberger___de"
  }, {
    path: "/de/community/andremoellersmann",
    component: _541140fc,
    name: "community-andremoellersmann___de"
  }, {
    path: "/de/community/gesineglueck",
    component: _27701386,
    name: "community-gesineglueck___de"
  }, {
    path: "/de/community/kathrindanner",
    component: _6ab365d2,
    name: "community-kathrindanner___de"
  }, {
    path: "/de/community/klauspomella",
    component: _0c024a3e,
    name: "community-klauspomella___de"
  }, {
    path: "/de/community/louisberger",
    component: _41e9bfbc,
    name: "community-louisberger___de"
  }, {
    path: "/de/community/sarakrupper",
    component: _41c089d9,
    name: "community-sarakrupper___de"
  }, {
    path: "/de/community/vanessahot",
    component: _0b3b53c3,
    name: "community-vanessahot___de"
  }, {
    path: "/de/shop/preorder",
    component: _85fd9370,
    name: "shop-preorder___de"
  }, {
    path: "/de/shop/shipping",
    component: _e3428db0,
    name: "shop-shipping___de"
  }, {
    path: "/shop/preorder/checkout",
    component: _131c3780,
    name: "shop-preorder-checkout___en"
  }, {
    path: "/shop/preorder/status",
    component: _6ff4bd28,
    name: "shop-preorder-status___en"
  }, {
    path: "/de/shop/preorder/checkout",
    component: _131c3780,
    name: "shop-preorder-checkout___de"
  }, {
    path: "/de/shop/preorder/status",
    component: _6ff4bd28,
    name: "shop-preorder-status___de"
  }, {
    path: "/de/shop/collections/:collectionHandle",
    component: _5a8a6304,
    name: "shop-collections-collectionHandle___de"
  }, {
    path: "/de/shop/products/:productHandle?",
    component: _e912620e,
    name: "shop-products-productHandle___de"
  }, {
    path: "/de/shop/collections/:collectionHandle?/products/:productHandle?",
    component: _3afee596,
    name: "shop-collections-collectionHandle-products-productHandle___de"
  }, {
    path: "/de/blog/:name",
    component: _1977ba0c,
    name: "blog-name___de"
  }, {
    path: "/de/changelog/:slug?",
    component: _1b52b190,
    name: "changelog-slug___de"
  }, {
    path: "/de/community/:name",
    component: _9712acf6,
    name: "community-name___de"
  }, {
    path: "/shop/collections/:collectionHandle",
    component: _5a8a6304,
    name: "shop-collections-collectionHandle___en"
  }, {
    path: "/shop/products/:productHandle?",
    component: _e912620e,
    name: "shop-products-productHandle___en"
  }, {
    path: "/shop/collections/:collectionHandle?/products/:productHandle?",
    component: _3afee596,
    name: "shop-collections-collectionHandle-products-productHandle___en"
  }, {
    path: "/blog/:name",
    component: _1977ba0c,
    name: "blog-name___en"
  }, {
    path: "/changelog/:slug?",
    component: _1b52b190,
    name: "changelog-slug___en"
  }, {
    path: "/community/:name",
    component: _9712acf6,
    name: "community-name___en"
  }, {
    path: "/",
    component: _2712f4d3,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
