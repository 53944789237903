
import IconCheck from '../icons/IconCheck.vue';
import IconClose from '../icons/IconClose.vue';
import LoadingIcon from '../icons/LoadingIcon.vue';

export default {
  name: 'UfsButton',
  components: { LoadingIcon, IconCheck, IconClose },
  props: {
    text: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    successMessage: {
      type: String,
      default: 'Done.'
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Error.'
    },
    buttonClass: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandler () {
      if (!this.disabled && !this.loading) {
        this.$emit('click');
      }
    }
  }
};
