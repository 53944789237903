export const BlogList = () => import('../../components/blog/BlogList.vue' /* webpackChunkName: "components/blog-list" */).then(c => wrapFunctional(c.default || c))
export const BlogListPreviewItem = () => import('../../components/blog/BlogListPreviewItem.vue' /* webpackChunkName: "components/blog-list-preview-item" */).then(c => wrapFunctional(c.default || c))
export const BlogPostCitation = () => import('../../components/blog/BlogPostCitation.vue' /* webpackChunkName: "components/blog-post-citation" */).then(c => wrapFunctional(c.default || c))
export const BlogPostHeadline = () => import('../../components/blog/BlogPostHeadline.vue' /* webpackChunkName: "components/blog-post-headline" */).then(c => wrapFunctional(c.default || c))
export const BlogPostImage = () => import('../../components/blog/BlogPostImage.vue' /* webpackChunkName: "components/blog-post-image" */).then(c => wrapFunctional(c.default || c))
export const BlogPostIntro = () => import('../../components/blog/BlogPostIntro.vue' /* webpackChunkName: "components/blog-post-intro" */).then(c => wrapFunctional(c.default || c))
export const BlogPostSectionHeadline = () => import('../../components/blog/BlogPostSectionHeadline.vue' /* webpackChunkName: "components/blog-post-section-headline" */).then(c => wrapFunctional(c.default || c))
export const BlogPostShare = () => import('../../components/blog/BlogPostShare.vue' /* webpackChunkName: "components/blog-post-share" */).then(c => wrapFunctional(c.default || c))
export const BlogPostSubtitle = () => import('../../components/blog/BlogPostSubtitle.vue' /* webpackChunkName: "components/blog-post-subtitle" */).then(c => wrapFunctional(c.default || c))
export const BlogPostTextBlock = () => import('../../components/blog/BlogPostTextBlock.vue' /* webpackChunkName: "components/blog-post-text-block" */).then(c => wrapFunctional(c.default || c))
export const BlogPreview = () => import('../../components/blog/BlogPreview.vue' /* webpackChunkName: "components/blog-preview" */).then(c => wrapFunctional(c.default || c))
export const BlogPreviewImage = () => import('../../components/blog/BlogPreviewImage.vue' /* webpackChunkName: "components/blog-preview-image" */).then(c => wrapFunctional(c.default || c))
export const BlogPreviewText = () => import('../../components/blog/BlogPreviewText.vue' /* webpackChunkName: "components/blog-preview-text" */).then(c => wrapFunctional(c.default || c))
export const CommunityCheckoutPortfolio = () => import('../../components/community/CheckoutPortfolio.vue' /* webpackChunkName: "components/community-checkout-portfolio" */).then(c => wrapFunctional(c.default || c))
export const CommunityBadge = () => import('../../components/community/CommunityBadge.vue' /* webpackChunkName: "components/community-badge" */).then(c => wrapFunctional(c.default || c))
export const CommunityJoinCommunityCta = () => import('../../components/community/JoinCommunityCta.vue' /* webpackChunkName: "components/community-join-community-cta" */).then(c => wrapFunctional(c.default || c))
export const CommunityMemberPreview = () => import('../../components/community/MemberPreview.vue' /* webpackChunkName: "components/community-member-preview" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../components/cookie/CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const DevComponentShowcase = () => import('../../components/dev/ComponentShowcase.vue' /* webpackChunkName: "components/dev-component-showcase" */).then(c => wrapFunctional(c.default || c))
export const GenericFullscreenVideoPlayer = () => import('../../components/generic/FullscreenVideoPlayer.vue' /* webpackChunkName: "components/generic-fullscreen-video-player" */).then(c => wrapFunctional(c.default || c))
export const GenericNewsletterSignupInput = () => import('../../components/generic/NewsletterSignupInput.vue' /* webpackChunkName: "components/generic-newsletter-signup-input" */).then(c => wrapFunctional(c.default || c))
export const GenericReferallList = () => import('../../components/generic/ReferallList.vue' /* webpackChunkName: "components/generic-referall-list" */).then(c => wrapFunctional(c.default || c))
export const GenericReferallListItem = () => import('../../components/generic/ReferallListItem.vue' /* webpackChunkName: "components/generic-referall-list-item" */).then(c => wrapFunctional(c.default || c))
export const GenericTab = () => import('../../components/generic/Tab.vue' /* webpackChunkName: "components/generic-tab" */).then(c => wrapFunctional(c.default || c))
export const GenericTabs = () => import('../../components/generic/Tabs.vue' /* webpackChunkName: "components/generic-tabs" */).then(c => wrapFunctional(c.default || c))
export const GenericTextTag = () => import('../../components/generic/TextTag.vue' /* webpackChunkName: "components/generic-text-tag" */).then(c => wrapFunctional(c.default || c))
export const GenericTooltip = () => import('../../components/generic/Tooltip.vue' /* webpackChunkName: "components/generic-tooltip" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowDown = () => import('../../components/icons/IconArrowDown.vue' /* webpackChunkName: "components/icons-icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowRight = () => import('../../components/icons/IconArrowRight.vue' /* webpackChunkName: "components/icons-icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowRightSmall = () => import('../../components/icons/IconArrowRightSmall.vue' /* webpackChunkName: "components/icons-icon-arrow-right-small" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowUpRight = () => import('../../components/icons/IconArrowUpRight.vue' /* webpackChunkName: "components/icons-icon-arrow-up-right" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBrand = () => import('../../components/icons/IconBrand.vue' /* webpackChunkName: "components/icons-icon-brand" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBrandActive = () => import('../../components/icons/IconBrandActive.vue' /* webpackChunkName: "components/icons-icon-brand-active" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCheck = () => import('../../components/icons/IconCheck.vue' /* webpackChunkName: "components/icons-icon-check" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevron = () => import('../../components/icons/IconChevron.vue' /* webpackChunkName: "components/icons-icon-chevron" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronBig = () => import('../../components/icons/IconChevronBig.vue' /* webpackChunkName: "components/icons-icon-chevron-big" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronMini = () => import('../../components/icons/IconChevronMini.vue' /* webpackChunkName: "components/icons-icon-chevron-mini" */).then(c => wrapFunctional(c.default || c))
export const IconsIconClose = () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icons-icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconsIconDeliver = () => import('../../components/icons/IconDeliver.vue' /* webpackChunkName: "components/icons-icon-deliver" */).then(c => wrapFunctional(c.default || c))
export const IconsIconDeliverActive = () => import('../../components/icons/IconDeliverActive.vue' /* webpackChunkName: "components/icons-icon-deliver-active" */).then(c => wrapFunctional(c.default || c))
export const IconsIconEnvelope = () => import('../../components/icons/IconEnvelope.vue' /* webpackChunkName: "components/icons-icon-envelope" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFilterButton = () => import('../../components/icons/IconFilterButton.vue' /* webpackChunkName: "components/icons-icon-filter-button" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLine = () => import('../../components/icons/IconLine.vue' /* webpackChunkName: "components/icons-icon-line" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLineBig = () => import('../../components/icons/IconLineBig.vue' /* webpackChunkName: "components/icons-icon-line-big" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMake = () => import('../../components/icons/IconMake.vue' /* webpackChunkName: "components/icons-icon-make" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMakeActive = () => import('../../components/icons/IconMakeActive.vue' /* webpackChunkName: "components/icons-icon-make-active" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMenu = () => import('../../components/icons/IconMenu.vue' /* webpackChunkName: "components/icons-icon-menu" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMinus = () => import('../../components/icons/IconMinus.vue' /* webpackChunkName: "components/icons-icon-minus" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPlan = () => import('../../components/icons/IconPlan.vue' /* webpackChunkName: "components/icons-icon-plan" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPlanActive = () => import('../../components/icons/IconPlanActive.vue' /* webpackChunkName: "components/icons-icon-plan-active" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPlus = () => import('../../components/icons/IconPlus.vue' /* webpackChunkName: "components/icons-icon-plus" */).then(c => wrapFunctional(c.default || c))
export const IconsIconRoundLine = () => import('../../components/icons/IconRoundLine.vue' /* webpackChunkName: "components/icons-icon-round-line" */).then(c => wrapFunctional(c.default || c))
export const IconsIconShare = () => import('../../components/icons/IconShare.vue' /* webpackChunkName: "components/icons-icon-share" */).then(c => wrapFunctional(c.default || c))
export const IconsIconSource = () => import('../../components/icons/IconSource.vue' /* webpackChunkName: "components/icons-icon-source" */).then(c => wrapFunctional(c.default || c))
export const IconsIconSourceActive = () => import('../../components/icons/IconSourceActive.vue' /* webpackChunkName: "components/icons-icon-source-active" */).then(c => wrapFunctional(c.default || c))
export const IconsIconStore = () => import('../../components/icons/IconStore.vue' /* webpackChunkName: "components/icons-icon-store" */).then(c => wrapFunctional(c.default || c))
export const IconsIconStoreActive = () => import('../../components/icons/IconStoreActive.vue' /* webpackChunkName: "components/icons-icon-store-active" */).then(c => wrapFunctional(c.default || c))
export const IconsIconUfsLogo = () => import('../../components/icons/IconUfsLogo.vue' /* webpackChunkName: "components/icons-icon-ufs-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsIconVideoPlayer = () => import('../../components/icons/IconVideoPlayer.vue' /* webpackChunkName: "components/icons-icon-video-player" */).then(c => wrapFunctional(c.default || c))
export const IconsIconWrapper = () => import('../../components/icons/IconWrapper.vue' /* webpackChunkName: "components/icons-icon-wrapper" */).then(c => wrapFunctional(c.default || c))
export const IconsLoadingIcon = () => import('../../components/icons/LoadingIcon.vue' /* webpackChunkName: "components/icons-loading-icon" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsButton = () => import('../../components/inputs/UfsButton.vue' /* webpackChunkName: "components/inputs-ufs-button" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsCheckbox = () => import('../../components/inputs/UfsCheckbox.vue' /* webpackChunkName: "components/inputs-ufs-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsDropdown = () => import('../../components/inputs/UfsDropdown.vue' /* webpackChunkName: "components/inputs-ufs-dropdown" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsInputWithButton = () => import('../../components/inputs/UfsInputWithButton.vue' /* webpackChunkName: "components/inputs-ufs-input-with-button" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsNumberPlusMinusInput = () => import('../../components/inputs/UfsNumberPlusMinusInput.vue' /* webpackChunkName: "components/inputs-ufs-number-plus-minus-input" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsSplitButton = () => import('../../components/inputs/UfsSplitButton.vue' /* webpackChunkName: "components/inputs-ufs-split-button" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsTextInput = () => import('../../components/inputs/UfsTextInput.vue' /* webpackChunkName: "components/inputs-ufs-text-input" */).then(c => wrapFunctional(c.default || c))
export const InputsUfsTextarea = () => import('../../components/inputs/UfsTextarea.vue' /* webpackChunkName: "components/inputs-ufs-textarea" */).then(c => wrapFunctional(c.default || c))
export const LocalizationLanguageInput = () => import('../../components/localization/LanguageInput.vue' /* webpackChunkName: "components/localization-language-input" */).then(c => wrapFunctional(c.default || c))
export const ModalCustomModal = () => import('../../components/modal/CustomModal.vue' /* webpackChunkName: "components/modal-custom-modal" */).then(c => wrapFunctional(c.default || c))
export const NavigationBigFooter = () => import('../../components/navigation/BigFooter.vue' /* webpackChunkName: "components/navigation-big-footer" */).then(c => wrapFunctional(c.default || c))
export const NavigationNewCareerCta = () => import('../../components/navigation/NewCareerCta.vue' /* webpackChunkName: "components/navigation-new-career-cta" */).then(c => wrapFunctional(c.default || c))
export const NavigationShopBreadcrumbs = () => import('../../components/navigation/ShopBreadcrumbs.vue' /* webpackChunkName: "components/navigation-shop-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const NavigationSmallNewCareerCta = () => import('../../components/navigation/SmallNewCareerCta.vue' /* webpackChunkName: "components/navigation-small-new-career-cta" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsReadyToTalk = () => import('../../components/page-components/ReadyToTalk.vue' /* webpackChunkName: "components/page-components-ready-to-talk" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsReadyToTalkWithVideo = () => import('../../components/page-components/ReadyToTalkWithVideo.vue' /* webpackChunkName: "components/page-components-ready-to-talk-with-video" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionCTA = () => import('../../components/shop/CollectionCTA.vue' /* webpackChunkName: "components/shop-collection-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionPreview = () => import('../../components/shop/CollectionPreview.vue' /* webpackChunkName: "components/shop-collection-preview" */).then(c => wrapFunctional(c.default || c))
export const ShopProductGrid = () => import('../../components/shop/ProductGrid.vue' /* webpackChunkName: "components/shop-product-grid" */).then(c => wrapFunctional(c.default || c))
export const ShopProductGridItem = () => import('../../components/shop/ProductGridItem.vue' /* webpackChunkName: "components/shop-product-grid-item" */).then(c => wrapFunctional(c.default || c))
export const ShopProductGridItemColorDisplay = () => import('../../components/shop/ProductGridItemColorDisplay.vue' /* webpackChunkName: "components/shop-product-grid-item-color-display" */).then(c => wrapFunctional(c.default || c))
export const ShopProductGridWithFilter = () => import('../../components/shop/ProductGridWithFilter.vue' /* webpackChunkName: "components/shop-product-grid-with-filter" */).then(c => wrapFunctional(c.default || c))
export const ShopSimpleProductGrid = () => import('../../components/shop/SimpleProductGrid.vue' /* webpackChunkName: "components/shop-simple-product-grid" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoadingProductGridWithFilterSkeleton = () => import('../../components/skeleton-loading/ProductGridWithFilterSkeleton.vue' /* webpackChunkName: "components/skeleton-loading-product-grid-with-filter-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoadingProductVariantColorDropdownSkeleton = () => import('../../components/skeleton-loading/ProductVariantColorDropdownSkeleton.vue' /* webpackChunkName: "components/skeleton-loading-product-variant-color-dropdown-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoadingProductVariantSizeDropdownSkeleton = () => import('../../components/skeleton-loading/ProductVariantSizeDropdownSkeleton.vue' /* webpackChunkName: "components/skeleton-loading-product-variant-size-dropdown-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoadingProduktGridItemSkeleton = () => import('../../components/skeleton-loading/ProduktGridItemSkeleton.vue' /* webpackChunkName: "components/skeleton-loading-produkt-grid-item-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonPane = () => import('../../components/skeleton-loading/SkeletonPane.vue' /* webpackChunkName: "components/skeleton-pane" */).then(c => wrapFunctional(c.default || c))
export const SlideshowsSlideshowNavigation = () => import('../../components/slideshows/SlideshowNavigation.vue' /* webpackChunkName: "components/slideshows-slideshow-navigation" */).then(c => wrapFunctional(c.default || c))
export const GenericSharingShareBar = () => import('../../components/generic/sharing/ShareBar.vue' /* webpackChunkName: "components/generic-sharing-share-bar" */).then(c => wrapFunctional(c.default || c))
export const IconsLogosLogoBlack = () => import('../../components/icons/logos/LogoBlack.vue' /* webpackChunkName: "components/icons-logos-logo-black" */).then(c => wrapFunctional(c.default || c))
export const IconsLogosLogoWhite = () => import('../../components/icons/logos/LogoWhite.vue' /* webpackChunkName: "components/icons-logos-logo-white" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialsIconFacebook = () => import('../../components/icons/socials/IconFacebook.vue' /* webpackChunkName: "components/icons-socials-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialsIconLinkedin = () => import('../../components/icons/socials/IconLinkedin.vue' /* webpackChunkName: "components/icons-socials-icon-linkedin" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialsIconTwitter = () => import('../../components/icons/socials/IconTwitter.vue' /* webpackChunkName: "components/icons-socials-icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialsIconWhatsapp = () => import('../../components/icons/socials/IconWhatsapp.vue' /* webpackChunkName: "components/icons-socials-icon-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const InputsMultiselectUfsMultiselectBase = () => import('../../components/inputs/Multiselect/UfsMultiselectBase.vue' /* webpackChunkName: "components/inputs-multiselect-ufs-multiselect-base" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenuCartMenuButton = () => import('../../components/navigation/menu/CartMenuButton.vue' /* webpackChunkName: "components/navigation-menu-cart-menu-button" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenuFullscreenMenu = () => import('../../components/navigation/menu/FullscreenMenu.vue' /* webpackChunkName: "components/navigation-menu-fullscreen-menu" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenuBar = () => import('../../components/navigation/menu/MenuBar.vue' /* webpackChunkName: "components/navigation-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenuLanguageSelector = () => import('../../components/navigation/menu/MenuLanguageSelector.vue' /* webpackChunkName: "components/navigation-menu-language-selector" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageAboutIntro = () => import('../../components/page-components/aboutpage/AboutIntro.vue' /* webpackChunkName: "components/page-components-aboutpage-about-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageAboutIntroSlideshow = () => import('../../components/page-components/aboutpage/AboutIntroSlideshow.vue' /* webpackChunkName: "components/page-components-aboutpage-about-intro-slideshow" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageClientSlideshow = () => import('../../components/page-components/aboutpage/ClientSlideshow.vue' /* webpackChunkName: "components/page-components-aboutpage-client-slideshow" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageClientSlideshowSlide = () => import('../../components/page-components/aboutpage/ClientSlideshowSlide.vue' /* webpackChunkName: "components/page-components-aboutpage-client-slideshow-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpagePromotionTabs = () => import('../../components/page-components/aboutpage/PromotionTabs.vue' /* webpackChunkName: "components/page-components-aboutpage-promotion-tabs" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpagePromotionalSlideshowMobile = () => import('../../components/page-components/aboutpage/PromotionalSlideshowMobile.vue' /* webpackChunkName: "components/page-components-aboutpage-promotional-slideshow-mobile" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpagePromotionalSlideshowMobileSlide = () => import('../../components/page-components/aboutpage/PromotionalSlideshowMobileSlide.vue' /* webpackChunkName: "components/page-components-aboutpage-promotional-slideshow-mobile-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageTextBlocks = () => import('../../components/page-components/aboutpage/TextBlocks.vue' /* webpackChunkName: "components/page-components-aboutpage-text-blocks" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageTrustElements = () => import('../../components/page-components/aboutpage/TrustElements.vue' /* webpackChunkName: "components/page-components-aboutpage-trust-elements" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageTypeOfClientsTab = () => import('../../components/page-components/aboutpage/TypeOfClientsTab.vue' /* webpackChunkName: "components/page-components-aboutpage-type-of-clients-tab" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsAboutpageTypeOfProductsTab = () => import('../../components/page-components/aboutpage/TypeOfProductsTab.vue' /* webpackChunkName: "components/page-components-aboutpage-type-of-products-tab" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsBlogpageBlogIntro = () => import('../../components/page-components/blogpage/BlogIntro.vue' /* webpackChunkName: "components/page-components-blogpage-blog-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsBlogpageBlogPostList = () => import('../../components/page-components/blogpage/BlogPostList.vue' /* webpackChunkName: "components/page-components-blogpage-blog-post-list" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsBlogpageBlogPostPage = () => import('../../components/page-components/blogpage/BlogPostPage.vue' /* webpackChunkName: "components/page-components-blogpage-blog-post-page" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCollectionpageCollectionDisplayItem = () => import('../../components/page-components/collectionpage/CollectionDisplayItem.vue' /* webpackChunkName: "components/page-components-collectionpage-collection-display-item" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCollectionpageCollectionDisplayItemBig = () => import('../../components/page-components/collectionpage/CollectionDisplayItemBig.vue' /* webpackChunkName: "components/page-components-collectionpage-collection-display-item-big" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCollectionpageCollectionDisplayList = () => import('../../components/page-components/collectionpage/CollectionDisplayList.vue' /* webpackChunkName: "components/page-components-collectionpage-collection-display-list" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCollectionpageCollectionIntro = () => import('../../components/page-components/collectionpage/CollectionIntro.vue' /* webpackChunkName: "components/page-components-collectionpage-collection-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCollectionpageCollectionsDisplay = () => import('../../components/page-components/collectionpage/CollectionsDisplay.vue' /* webpackChunkName: "components/page-components-collectionpage-collections-display" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCollectionpageIconTooltipContent = () => import('../../components/page-components/collectionpage/IconTooltipContent.vue' /* webpackChunkName: "components/page-components-collectionpage-icon-tooltip-content" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCommunitypageCommunityDetailsHeadline = () => import('../../components/page-components/communitypage/CommunityDetailsHeadline.vue' /* webpackChunkName: "components/page-components-communitypage-community-details-headline" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCommunitypageCommunityDetailsIntro = () => import('../../components/page-components/communitypage/CommunityDetailsIntro.vue' /* webpackChunkName: "components/page-components-communitypage-community-details-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCommunitypageCommunityDetailsPage = () => import('../../components/page-components/communitypage/CommunityDetailsPage.vue' /* webpackChunkName: "components/page-components-communitypage-community-details-page" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCommunitypageCommunityIntro = () => import('../../components/page-components/communitypage/CommunityIntro.vue' /* webpackChunkName: "components/page-components-communitypage-community-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsCommunitypageMemberList = () => import('../../components/page-components/communitypage/MemberList.vue' /* webpackChunkName: "components/page-components-communitypage-member-list" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsContactpageLetsTalkIntro = () => import('../../components/page-components/contactpage/LetsTalkIntro.vue' /* webpackChunkName: "components/page-components-contactpage-lets-talk-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsContactpageUFSContactInformation = () => import('../../components/page-components/contactpage/UFSContactInformation.vue' /* webpackChunkName: "components/page-components-contactpage-u-f-s-contact-information" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageFashionSlide = () => import('../../components/page-components/landingpage/FashionSlide.vue' /* webpackChunkName: "components/page-components-landingpage-fashion-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingBlog = () => import('../../components/page-components/landingpage/LandingBlog.vue' /* webpackChunkName: "components/page-components-landingpage-landing-blog" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingCommunity = () => import('../../components/page-components/landingpage/LandingCommunity.vue' /* webpackChunkName: "components/page-components-landingpage-landing-community" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingIntro = () => import('../../components/page-components/landingpage/LandingIntro.vue' /* webpackChunkName: "components/page-components-landingpage-landing-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingIntroAlt = () => import('../../components/page-components/landingpage/LandingIntroAlt.vue' /* webpackChunkName: "components/page-components-landingpage-landing-intro-alt" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingIntroTextSlide = () => import('../../components/page-components/landingpage/LandingIntroTextSlide.vue' /* webpackChunkName: "components/page-components-landingpage-landing-intro-text-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingNavigation = () => import('../../components/page-components/landingpage/LandingNavigation.vue' /* webpackChunkName: "components/page-components-landingpage-landing-navigation" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingNavigationAlt = () => import('../../components/page-components/landingpage/LandingNavigationAlt.vue' /* webpackChunkName: "components/page-components-landingpage-landing-navigation-alt" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingNavigationFinal = () => import('../../components/page-components/landingpage/LandingNavigationFinal.vue' /* webpackChunkName: "components/page-components-landingpage-landing-navigation-final" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingServiceAlt = () => import('../../components/page-components/landingpage/LandingServiceAlt.vue' /* webpackChunkName: "components/page-components-landingpage-landing-service-alt" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingServiceIntro = () => import('../../components/page-components/landingpage/LandingServiceIntro.vue' /* webpackChunkName: "components/page-components-landingpage-landing-service-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageLandingServiceSlideshowSlide = () => import('../../components/page-components/landingpage/LandingServiceSlideshowSlide.vue' /* webpackChunkName: "components/page-components-landingpage-landing-service-slideshow-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageServiceSlide = () => import('../../components/page-components/landingpage/ServiceSlide.vue' /* webpackChunkName: "components/page-components-landingpage-service-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsLandingpageUnitedSlide = () => import('../../components/page-components/landingpage/UnitedSlide.vue' /* webpackChunkName: "components/page-components-landingpage-united-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageInteractiveServiceComponent = () => import('../../components/page-components/servicepage/InteractiveServiceComponent.vue' /* webpackChunkName: "components/page-components-servicepage-interactive-service-component" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarIcon = () => import('../../components/page-components/servicepage/ServiceBarIcon.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-icon" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceIntro = () => import('../../components/page-components/servicepage/ServiceIntro.vue' /* webpackChunkName: "components/page-components-servicepage-service-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsShoppageMobileShopIntroSlideshow = () => import('../../components/page-components/shoppage/MobileShopIntroSlideshow.vue' /* webpackChunkName: "components/page-components-shoppage-mobile-shop-intro-slideshow" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsShoppageMobileShopIntroSlideshowOverlay = () => import('../../components/page-components/shoppage/MobileShopIntroSlideshowOverlay.vue' /* webpackChunkName: "components/page-components-shoppage-mobile-shop-intro-slideshow-overlay" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsShoppageShopIntro = () => import('../../components/page-components/shoppage/ShopIntro.vue' /* webpackChunkName: "components/page-components-shoppage-shop-intro" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsShoppageSingleShopIntro = () => import('../../components/page-components/shoppage/SingleShopIntro.vue' /* webpackChunkName: "components/page-components-shoppage-single-shop-intro" */).then(c => wrapFunctional(c.default || c))
export const ShopCartModule = () => import('../../components/shop/cart/CartModule.vue' /* webpackChunkName: "components/shop-cart-module" */).then(c => wrapFunctional(c.default || c))
export const ShopCartModuleItemMobileRow = () => import('../../components/shop/cart/CartModuleItemMobileRow.vue' /* webpackChunkName: "components/shop-cart-module-item-mobile-row" */).then(c => wrapFunctional(c.default || c))
export const ShopCartModuleRow = () => import('../../components/shop/cart/CartModuleRow.vue' /* webpackChunkName: "components/shop-cart-module-row" */).then(c => wrapFunctional(c.default || c))
export const ShopCartOverlay = () => import('../../components/shop/cart/CartOverlay.vue' /* webpackChunkName: "components/shop-cart-overlay" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionFilter = () => import('../../components/shop/collection/CollectionFilter.vue' /* webpackChunkName: "components/shop-collection-filter" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionHeader = () => import('../../components/shop/collection/CollectionHeader.vue' /* webpackChunkName: "components/shop-collection-header" */).then(c => wrapFunctional(c.default || c))
export const ShopProductCollectionHeader = () => import('../../components/shop/product/ProductCollectionHeader.vue' /* webpackChunkName: "components/shop-product-collection-header" */).then(c => wrapFunctional(c.default || c))
export const ShopProductDescription = () => import('../../components/shop/product/ProductDescription.vue' /* webpackChunkName: "components/shop-product-description" */).then(c => wrapFunctional(c.default || c))
export const ShopProductImageGallery = () => import('../../components/shop/product/ProductImageGallery.vue' /* webpackChunkName: "components/shop-product-image-gallery" */).then(c => wrapFunctional(c.default || c))
export const ShopProductPage = () => import('../../components/shop/product/ProductPage.vue' /* webpackChunkName: "components/shop-product-page" */).then(c => wrapFunctional(c.default || c))
export const ShopProductPageCollectionPreview = () => import('../../components/shop/product/ProductPageCollectionPreview.vue' /* webpackChunkName: "components/shop-product-page-collection-preview" */).then(c => wrapFunctional(c.default || c))
export const ShopProductPageProductFacts = () => import('../../components/shop/product/ProductPageProductFacts.vue' /* webpackChunkName: "components/shop-product-page-product-facts" */).then(c => wrapFunctional(c.default || c))
export const ShopProductSizeChart = () => import('../../components/shop/product/ProductSizeChart.vue' /* webpackChunkName: "components/shop-product-size-chart" */).then(c => wrapFunctional(c.default || c))
export const ShopProductVariantColorDropdown = () => import('../../components/shop/product/ProductVariantColorDropdown.vue' /* webpackChunkName: "components/shop-product-variant-color-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ShopProductVariantDropdownBase = () => import('../../components/shop/product/ProductVariantDropdownBase.vue' /* webpackChunkName: "components/shop-product-variant-dropdown-base" */).then(c => wrapFunctional(c.default || c))
export const ShopProductVariantSizeDropdown = () => import('../../components/shop/product/ProductVariantSizeDropdown.vue' /* webpackChunkName: "components/shop-product-variant-size-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SlideshowsShopMobileShopIntroSlideshowSlide = () => import('../../components/slideshows/shop/MobileShopIntroSlideshowSlide.vue' /* webpackChunkName: "components/slideshows-shop-mobile-shop-intro-slideshow-slide" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenuMiniShopMenuBarItem = () => import('../../components/navigation/menu/MiniShopMenuBar/MiniShopMenuBarItem.vue' /* webpackChunkName: "components/navigation-menu-mini-shop-menu-bar-item" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenuMiniShopMenubar = () => import('../../components/navigation/menu/MiniShopMenuBar/MiniShopMenubar.vue' /* webpackChunkName: "components/navigation-menu-mini-shop-menubar" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarDesktopServiceBar = () => import('../../components/page-components/servicepage/ServiceBar/DesktopServiceBar.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-desktop-service-bar" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarDesktopServiceBarSlide = () => import('../../components/page-components/servicepage/ServiceBar/DesktopServiceBarSlide.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-desktop-service-bar-slide" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarResponsiveAnimatedLine = () => import('../../components/page-components/servicepage/ServiceBar/ResponsiveAnimatedLine.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-responsive-animated-line" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarResponsiveLine = () => import('../../components/page-components/servicepage/ServiceBar/ResponsiveLine.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-responsive-line" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBar = () => import('../../components/page-components/servicepage/ServiceBar/ServiceBar.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarDescriptionItem = () => import('../../components/page-components/servicepage/ServiceBar/ServiceBarDescriptionItem.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-description-item" */).then(c => wrapFunctional(c.default || c))
export const PageComponentsServicepageServiceBarDescriptionItemDeprecated = () => import('../../components/page-components/servicepage/ServiceBar/ServiceBarDescriptionItemDeprecated.vue' /* webpackChunkName: "components/page-components-servicepage-service-bar-description-item-deprecated" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionCollectionFiltersFilterStatusButton = () => import('../../components/shop/collection/CollectionFilters/FilterStatusButton.vue' /* webpackChunkName: "components/shop-collection-collection-filters-filter-status-button" */).then(c => wrapFunctional(c.default || c))
export const ShopProductFusemeInfoIcons = () => import('../../components/shop/product/fuseme/InfoIcons.vue' /* webpackChunkName: "components/shop-product-fuseme-info-icons" */).then(c => wrapFunctional(c.default || c))
export const ShopProductFusemeUsageIcons = () => import('../../components/shop/product/fuseme/UsageIcons.vue' /* webpackChunkName: "components/shop-product-fuseme-usage-icons" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionColorFilter = () => import('../../components/shop/collection/CollectionFilters/ColorFilter/CollectionColorFilter.vue' /* webpackChunkName: "components/shop-collection-color-filter" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionColorFilterRow = () => import('../../components/shop/collection/CollectionFilters/ColorFilter/CollectionColorFilterRow.vue' /* webpackChunkName: "components/shop-collection-color-filter-row" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionSizeFilter = () => import('../../components/shop/collection/CollectionFilters/SizeFilter/CollectionSizeFilter.vue' /* webpackChunkName: "components/shop-collection-size-filter" */).then(c => wrapFunctional(c.default || c))
export const ShopCollectionSizeFilterRow = () => import('../../components/shop/collection/CollectionFilters/SizeFilter/CollectionSizeFilterRow.vue' /* webpackChunkName: "components/shop-collection-size-filter-row" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
